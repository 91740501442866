import React from 'react';
import BlackTextVideoSection from '../components/BlackTextVideoSection/BlackTextVideoSection';
import GeneralInquiriesForm from '../components/GeneralInquiries/GeneralInquiriesForm';
import HeroSection from '../components/HeroSection/HeroSection';
import { contactContent } from '../content/contact';
import SEO from '../components/seo'
import ParallaxSection from "../components/ParallaxSection/ParallaxSection"

const Contact = () => {
  return (
    <React.Fragment>
      <SEO title={"Contact"} />
      <HeroSection {...contactContent.heroSection} />
      <BlackTextVideoSection {...contactContent.blackTextVideoSection} indexPage />
      <ParallaxSection {...contactContent.parallax}/>
      <GeneralInquiriesForm pageName="contact" />
    </React.Fragment>
  );
};

export default Contact;
