import contactHero from "../images/Contact_Header_BG.jpg"
import classes from "../components/HeroSection/HeroSection.module.css"
import indexBlackText from "../images/black-text-section.jpg"
import contactParallax from "../images/Contact_Parallax-optimized.jpg"

export const contactContent = {
  heroSection: {
    sectionId: "Contact",
    background: contactHero,
    heroImageClass: "headerImageClass",
    imageFile: "cow.png",
    alt: "cow",
    headerText: "CONTACT",
    displayText:
      "THE FIRST NATURALLY SELECTED, DNA AUTHENTICATED BEEF TENDERNESS RATING",
    bottomImage: "Contact_Burger.png",
    bottomImageClass: classes.contactImageWrapper,
  },
  blackTextVideoSection: {
    sectionId: "questions-comments",
    background: indexBlackText,
    displayText: [
      {
        text:
          "For questions about our certifications, please contact Tenet™ Beef Tenetbeef@gmail.com",
        color: "rgba(255, 255, 255, 1)",
      },
    ],
  },
  parallax: {
    sectionId: "contact-parallax",
    background: contactParallax,
    alt: "parallax-one-image",
  },
}
